<template>
  <div class="home">
    <div class="pro_vice_info bold" ><strong>Pro více informací volejte 602 159 169</strong></div>
    <div class="param_selection">
      <div class="popis">Zadejte zákaznické číslo:</div>
      <input type="text" v-model="zakCislo" />
      <vue-recaptcha sitekey="6Lc-OTwcAAAAAHA7mrhjLih3eaZAe0Hixh5exYUC">
      <button @click="selectEquipment()">Vyber</button>
      </vue-recaptcha>
    </div>

    <div v-if="equipment != ''" class="nazev_firmy_block">
      <div style="font-size: 21px"><strong>{{ equipment[0]["FIRMA ID"] }}</strong></div>
      <!-- <div class="bold">Pro více informací volejte 602 159 169</div> -->
    </div>
    <div v-if="equipment != ''">
      <table>
        <tr class="bold">
          <th>Kód zboží</th>
          <th>Název</th>
          <th>SN</th>
          <th>Poslední profylaxe</th>
          <th>Plánovaná profylaxe</th>
        </tr>
        <tr
          v-for="(item, index) in equipment"
          v-bind:key="index"
          :class="rowDynamicCollor(item['DAT NEXT'])"
          @click="getHistory(item['SERIAL ID'], item['MAT ID'])"
        >
          <td><strong>{{ item["MAT ID"] }}</strong></td>
          <td>{{ item["NAZEV MAT"] }}</td>
          <td>{{ item["SERIAL ID"] }}</td>
          <td>{{ item["DAT REAL"].substr(0, 10) }}</td>
          <td>{{ item["DAT NEXT"].substr(0, 10) }}</td>
        </tr>
      </table>
    </div>

    <v-dialog v-if="dialog" v-model="dialog" width="750">
      <v-card min-width="750">
        <div class="history_header">
          <div class="close_icon" @click="closeDialog()"></div>
          <div class="popis bold">Kód</div>
          <div class="history_Header_text">{{ history[0]["MAT ID"] }}</div>
          <div class="popis bold">Název:</div>
          <div class="history_Header_text">{{ history[0]["NAZEV MAT"] }}</div>
          <div class="popis bold">SN:</div>
          <div class="history_Header_text">{{ history[0]["SERIAL ID"] }}</div>
        </div>
        <v-divider></v-divider>

        <table class="history_table">
          <tr class="bold">
            <th>Poslední profylaxe</th>
            <th>Plánovaná profylaxe</th>
            <th>Interval</th>
            <th>Poznámka</th>
            <th>SMAN</th>
            <th>Aktivní</th>
            <!-- <th>MAT ID</th> -->
          </tr>
          <tr v-for="(item, index) in history" v-bind:key="index">
            <td>{{ getDateFormat(index, "DAT REAL") }}</td>
            <td>{{ getDateFormat(index, "DAT NEXT") }}</td>
            <td>{{ item["INTERVAL"] }}</td>
            <td>{{ item["POZN"] }}</td>
            <td>{{ item["SMAN1 ID"] }}</td>
            <td>{{ item["ACTIVE"] }}</td>
            <!-- <td>{{ item["MAT ID"] }}</td> -->
          </tr>
        </table>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "Home",
  components: { VueRecaptcha },
  created() {
    this.selectEquipment();
  },
  computed: {},
  data() {
    return {
      equipment: [],
      zakCislo: "",
      dialog: false,
      history: [],
      today: new Date().toISOString().substr(0, 10),
      plusMont: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10),
      plus2Weeks: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10),
      plusWeek: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10),
    };
  },
  methods: {
    selectEquipment() {
      this.equipment = [];
      if (this.zakCislo != "") {
        axios
          .post(this.$myServerPath + "/selectEquipment.php", {
            user: this.zakCislo,
          })
          .then((response) => {
            this.equipment = response.data;
            //console.log(response.data);
            if (response.data == null) {
              alert("Žádný odpovídající záznam nebyl nalezen.");
            }
          });
      } else {
        this.equipment = [];
      }
    },
    rowDynamicCollor(planned) {
      if (planned.substr(0, 10) < this.today) {
        return { red: true };
      }
      // if (planned.substr(0, 10) < this.plusMont) {
      //   return { yellow: true };
      // }
      // if (planned.substr(0, 10) < this.plus2Weeks) {
      //   return { orange: true };
      // }
      // if (planned.substr(0, 10) < this.plusWeek) {
      //   return { red1: true };
      // }
    },
    getHistory(sn, mat) {
      this.history = [];
      axios
        .post(this.$myServerPath + "/getHistory.php", {
          SN: sn,
          MAT: mat,
        })
        .then((response) => {
          this.history = response.data;
          // console.log(response.data);
          if (response.data == null) {
            alert("Žádný odpovídající záznam nebyl nalezen.");
          }
        });
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    getDateFormat(i, date) {
      if (this.history[i][date] != null) {
        return this.history[i][date].substr(0, 10);
      } else return "null";
    },
  },
};
</script>


<style scoped>
.pro_vice_info{
  text-align: right;
  padding: 0 15px;
}
.param_selection {
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 10px;
}
.popis {
  flex: 1 1 25%;
  padding: 0 5px;
  color: #f66111;
}
.nazev_firmy_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #E8ECEF;
  border-radius: 5px;
}
.history_Header_text {
  padding: 0 5px 10px 5px;
}
.close_icon {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 31px;
  color: black;
  cursor: pointer;
}
.close_icon:before {
  content: "×";
}
input {
  flex: 1 1 55%;
  margin: 5px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  color: gray;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid gray;
  border-radius: 5px;
}
input:focus {
  outline: none;
  border: 1px solid lightgray;
  box-shadow: 0 0 5px #007bff;
}
button {
  flex: 1 1 10%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1rem;
  line-height: 1.5;
  color: #28a745;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #28a745;
  cursor: pointer;
  margin: 5px;
}
button:hover {
  background-color: #28a745;
  color: white;
}
table {
  border-collapse: collapse;
  border-radius: 15px;
  width: 100%;
}
tr:nth-child(even) {
  background-color: #fef5ee;
}
th,
td {
  border-bottom: 1px solid lightgray;
  padding: 10px;
  text-align: left;
}
th {
  min-width: 120px;
}
td{
  cursor: pointer;
}
.red {
  background-color: rgb(255, 58, 58) !important;
}
/* .red1 {
  background-color: rgb(255, 81, 75) !important;
  border-radius: 10px;
}
.orange {
  background-color: rgb(250, 129, 125) !important;
  border-radius: 10px;
}
.yellow {
  background-color: rgb(229, 252, 127) !important;
  border-radius: 10px;
} */
.green {
  background-color: green;
}
.history_header {
  padding: 10px;
}
@media (max-width: 575px) {
  .pro_vice_info{
  text-align: center;
}

}
@media (max-width: 360px) {
  .param_selection {
    display: block;
    align-items: flex-start;
  }
}
</style>
