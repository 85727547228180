<template>
  <v-app>
    <div id="app">
      <div id="nav">
        <a class="logo" href="http://menu.in-gastro.cz/"
          ><img alt="Vue logo" src="@/assets/logo.svg"
        /></a>
        <!-- <div class="nav_text">PROFYLAXE</div> -->
        <div class="promo_text">
          Děláme pro vás <strong>profi kuchyně</strong>
        </div>
      </div>
      <router-view />
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {  },
  created() {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
/* #### Generated By: http://www.cufonfonts.com #### */

/* @font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/Segoe UI Regular.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/Segoe UI Bold.woff") format("woff");
} */

/* @font-face {
  font-family: "Segoe UI Italic";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/Segoe/Segoe UI Italic.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/Segoe/Segoe UI Bold Italic.woff") format("woff");
} */

html {
  box-sizing: border-box;
}
*,
::after,
::before {
  box-sizing: inherit;
}
body {
  display: flex;
  width: 68vw;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  font-size: 1rem;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" ;
}
/* .bold {
  font-family: 'Segoe UI Bold';  
} */
.v-application {
  /* font-family: "Segoe UI Regular" !important; */
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important ;
}
#app {
  flex: 1 1 100%;
  padding: 0 15px;
  color: #212529;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nav {
  display: flex;
  height: 80px;
  padding: 0 15px;
  margin-top: 45.69px;
}
.logo {
  flex: 1 1 50%;
}
.nav_text {
  margin-left: 20px;
}
.promo_text {
  text-align: right;
  flex: 1 1 50%;
}
@media (max-width: 1200px) {
  body,
  .popis,
  input,
  button,
  .nazev_firmy,
  .pro_vice_info,
  td {
    font-size: 1rem;
  }
}
@media (max-width: 992px) {
  body,
  .popis,
  input,
  button,
  .nazev_firmy,
  .pro_vice_info,
  td {
    font-size: 0.9rem;
  }
}
@media (max-width: 768px) {
  body,
  .popis,
  input,
  button,
  .nazev_firmy,
  .pro_vice_info,
  td {
    font-size: 0.8rem;
  }
  body {
    width: 90%;
  }
}
@media (max-width: 576px) {
  body,
  .popis,
  input,
  button,
  .nazev_firmy,
  .pro_vice_info,
  td {
    font-size: 0.7rem;
  }
  body {
    width: 100%;
  }
  #nav {
    flex-direction: column;
    justify-items: center;
    margin-top: 36px;
    height: 100px;
  }
  .logo {
    flex: 1 1 100%;
    text-align: center;
  }
  .promo_text {
    flex: 1 1 100%;
    text-align: center;
    padding-top: 10px;
  }
}
</style>
