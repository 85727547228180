import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

Vue.prototype.$myServerPath = "https://profylaxe.in-gastro.cz/"

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
